<template>
  <div id="account" v-cloak v-loading.fullscreen.lock="loading">
    <el-form
      label-width="150px"
      ref="accountForm"
      :model="from"
      :rules="rules"
      class="from"
    >
      <el-form-item :label="$t('account.name')" prop="name">
        <el-input v-model="from.name" clearable size="mini"></el-input>
      </el-form-item>
      <el-form-item :label="$t('account.accessionNumber')" prop="account">
        <el-input
          v-model="from.account"
          clearable
          size="mini"
          @blur="nameChange(from.account, 'account')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('account.mobile')" prop="mobile">
        <el-input
          v-model="from.mobile"
          clearable
          size="mini"
          @blur="nameChange(from.mobile, 'mobile')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('account.oldPassword')">
        <el-input
          v-model="from.oldPassword"
          type="password"
          show-password
          size="mini"
          autocomplete="new-password"
        ></el-input>
        <p v-if="showOldPasswordTips" class="red-tips">
          {{ $t("account.text") }}
        </p>
      </el-form-item>
      <el-form-item :label="$t('account.newPassword')">
        <el-input
          v-model="from.password"
          type="password"
          show-password
          size="mini"
          maxlength="20"
          autocomplete="new-password"
          @keyup.native="checkOldPassword()"
        ></el-input>
        <p v-if="showPasswordTips" class="red-tips">
          {{ $t("account.text1") }}
        </p>
      </el-form-item>
      <el-form-item :label="$t('account.confirmPassword')">
        <el-input
          v-model="from.msPassword"
          type="password"
          show-password
          size="mini"
          maxlength="20"
          @keyup.native="checkPassword()"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('account.avatar')">
        <!-- <el-upload
                        class="avatar-uploader"
                        :accept="uploadAccept"
                        action="{:url('home/index/picUpload')}"
                        :data="{photo: from.photo}"
                        :show-file-list="false"
                        :on-error="errorUpload"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="from.photo" :src="from.photo.indexOf('base64')>=0 ? from.photo :  '{:url('api/Common/viewAvatarImg')}'+'?path='+from.photo" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> -->
        <div class="upload-box">
          <img v-if="from.photo" :src="from.photo" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <input
            ref="referenceUpload"
            type="file"
            id="file-input"
            @change="fileUpload"
          />
        </div>
      </el-form-item>
      <div class="form-item">
        <h3 class="form-tit">
          <span>{{ $t("CrmCus.desc") }}</span>
        </h3>
        <div class="all-box">
          <el-input
            v-for="(item, index) in desc"
            size="mini"
            class="form-input desc-margin"
            v-model="desc[index]"
            :key="index"
          ></el-input>
        </div>
        <div class="handel-box">
          <i class="el-icon-circle-plus-outline hand m10" @click="addDesc"></i>
          <i class="el-icon-remove-outline m10 hand" @click="delDesc"></i>
        </div>
      </div>
      <el-form-item class="submitBox">
        <zc-icon-btn
          :content="$t('submit')"
          btn_type="primary"
          btn_class="form_button"
          class="innerFloatRight"
          icon="icon-tijiao"
          @click="submit"
        ></zc-icon-btn>
        <!-- <el-button @click="submit" type="primary" size="mini" class="floatRight">
                    <tags:lang>submit</tags:lang>
                </el-button> -->
      </el-form-item>
    </el-form>
    <el-dialog title="" :visible.sync="bigImgVisible" v-move width="1000px">
      <div class="big-img">
        <img :src="bigImgUrl" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bigImgVisible = false" size="mini">{{
          $t("close")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 图片裁剪弹窗 -->
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog
      :title="$t('account.imageCrop')"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="flex-a-center">
        <div class="cropper">
          <img id="image" :src="cropperImg" ref="image" />
        </div>
        <div class="preview-box">
          <h2>{{ $t("account.view") }}</h2>
          <div class="small"></div>
        </div>
      </div>
      <div class="cropper-handel flex-center">
        <el-button @click="myCropper.reset()" size="mini" type="success">{{
          $t("reset")
        }}</el-button>
        <el-button @click="myCropper.zoom(0.1)" size="mini" type="warning">{{
          $t("zoomIn")
        }}</el-button>
        <el-button @click="myCropper.zoom(-0.1)" size="mini" type="primary">{{
          $t("zoomOut")
        }}</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="finishImgCropper" size="mini">{{
          $t("confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@/plugins/move";
import Cropper from "cropperjs";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn.vue";
export default {
  components: {
    ZcIconBtn,
  },
  name: "Account",
  data() {
    return {
      loading: true,
      from: {
        name: "",
        account: "",
        oldPassword: "",
        password: "",
        msPassword: "",
        mobile: "",
        photo: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("account.text2"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 25,
            message: this.$t("account.text3"),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("account.text4"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 25,
            message: this.$t("account.text3"),
            trigger: "blur",
          },
        ],
      },
      desc: [""],
      account: "",
      showPasswordTips: false,
      showOldPasswordTips: false,
      imageUrl: "",
      imageUrlString: "",
      uploadAccept: ".png, .jpg, .jpeg, .gif", //上传限制文件
      size: 5 * 1024 * 1024,
      bigImgVisible: false,
      bigImgUrl: "",
      dialogVisible: false,
      myCropper: null,
      cropperImg: "",
      temPhoto: null,
    };
  },
  methods: {
    //增加备注
    addDesc() {
      this.desc.push("");
    },
    //删除备注
    delDesc() {
      if (this.desc.length == 1) {
        return;
      }
      this.desc.pop();
    },
    // 检测必填项
    checkPassword() {
      if (this.from.password === "" || this.from.password === undefined) {
        this.from.msPassword = "";
        this.showPasswordTips = true;
      } else {
        this.showPasswordTips = false;
      }
    },
    // 检测必填项
    checkOldPassword() {
      if (this.from.oldPassword === "" || this.from.oldPassword === undefined) {
        this.from.password = "";
        this.showOldPasswordTips = true;
      } else {
        this.showOldPasswordTips = false;
      }
    },
    //去除所有空格
    removeSpaces(name) {
      let b = "";
      if (name === null || name === undefined) {
        return;
      }
      for (let i = 0; i < name.length; i++) {
        if (name[i] != " ") {
          b = b + name[i];
        }
      }
      return b;
    },
    nameChange(name, model) {
      this.from[model] = this.removeSpaces(name);
    },
    getInfo() {
      let that = this;
      this.loading = true;
      this.axios
        .post("/home/Index/accountInfoGet")
        .then((res) => res.data)
        .then(function (res) {
          that.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          that.from = res.data;

          if (res.data.desc) {
            that.desc = res.data.desc.split("|**|");
          } else {
            that.desc = [""];
          }
        })
        .catch(() => {});
    },
    fileUpload(event) {
      let file = event.target.files[0];
      // 检测格式和大小
      let lastName = file.type.substr(6).toLowerCase();
      if (this.uploadAccept.indexOf(lastName) < 0) {
        this.$message.error(this.$t("projectInfo.warningText"));
        return false;
      }
      const isLt2M = file.size < this.size;
      if (!isLt2M) {
        this.$message.error(
          file.name +
            this.$t("account.sizeIs") +
            file.size / 1000 +
            "kb," +
            this.$t("account.exceed") +
            this.size +
            "kb"
        );
        return false;
      }
      // 转换为base64图片
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (!this.cropper) {
          setTimeout(() => {
            this.myCropper = new Cropper(this.$refs.image, {
              viewMode: 1,
              dragMode: "none",
              initialAspectRatio: 1,
              aspectRatio: 1,
              background: false,
              autoCropArea: 0.6,
              zoomOnWheel: false,
              preview: ".small",
            });
            this.myCropper.replace(e.target.result, false);
          });
        } else {
          this.myCropper.replace(e.target.result, false);
        }
      };

      this.$refs.referenceUpload.value = null;

      this.dialogVisible = true;
      return true;
    },
    finishImgCropper() {
      let resImg = this.myCropper
        .getCroppedCanvas({
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg");
      this.$set(this.from, "photo", resImg);
      this.dialogVisible = false;
    },
    submit() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          if (this.from.oldPassword.trim().length != 0) {
            if (this.from.password.trim() !== this.from.msPassword.trim()) {
              this.$message(toMessage("error", this.$t("account.error")));
              return;
            }
            if (
              this.from.password.trim().length < 6 ||
              this.from.msPassword.trim().length < 6
            ) {
              this.$message(toMessage("error", this.$t("account.error1")));
              return;
            }
          }
          let that = this;
          this.loading = true;
          console.log(that.from.photo);

          this.axios
            .post("/home/Index/accountInfoPut", {
              name: that.from.name.trim(),
              account: that.from.account.trim(),
              mobile: that.from.mobile.trim(),
              oldPassword: that.from.oldPassword.trim(),
              password: that.from.password.trim(),
              msPassword: that.from.msPassword.trim(),
              desc: this.desc.join("|**|"),
              photo: that.from.photo,
            })
            .then((res) => res.data)
            .then(function (res) {
              that.loading = false;
              if (res.status !== 1) {
                this.$message(toMessage("error", res.msg));
                return;
              }
              this.$message(toMessage("success", res.msg));
              // 修改账户 或者修改密码成功后 就刷新页面
              if (
                that.account !== that.from.account ||
                that.from.password !== ""
              ) {
                this.$router.push({ path: "/login" });
              }
            })
            .catch(() => {});
        }
      });
    },
  },
  created() {
    this.loading = false;
    this.getInfo();
  },
};
</script>

<style scoped>
@import url("~cropperjs/dist/cropper.min.css");
#account {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
.innerFloatRight {
  position: fixed;
  top: 60px;
  right: 50px;
  z-index: 999;
}
.from {
  width: 450px;
  margin: 0 auto;
}

.submitBox {
  display: flex;
  justify-content: flex-end;
}
.red-tips {
  color: red;
  line-height: 20px;
}
.form-tit {
  width: 150px;
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  padding-right: 10px;
  margin-right: 0;
}
.all-box {
  display: flex;
  flex-direction: column;
}
.all-box .form-input {
  margin-bottom: 10px;
}
.handel-box {
  padding-top: 6px;
  padding-left: 5px;
}
.form-input {
  width: 255px;
}
.cropper {
  height: 300px;
  overflow: hidden;
}
.cropper img {
  max-width: 100%;
  max-height: 100%;
}
.small {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}
.small img {
  max-width: 100%;
  max-height: 100%;
}
.preview-box {
  margin-left: 20px;
}
.preview-box h2 {
  margin-bottom: 10px;
  text-align: center;
}
.cropper-handel {
  margin-top: 10px;
}
.avatar {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.upload-box {
  position: relative;
  width: 60px;
  height: 60px;
}
#file-input {
  position: absolute;
  left: 0;
  cursor: pointer;
  height: 60px;
  width: 60px;
  opacity: 0;
}
</style>